var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":6,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"任务名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'name2', {rules: [{ required: true, message: '请输入任务名称', whitespace: true}]} ]),expression:"[ 'name2', {rules: [{ required: true, message: '请输入任务名称', whitespace: true}]} ]"}],attrs:{"placeholder":"请输入任务名称"}})],1)],1),_c('a-col',{attrs:{"xl":{span: 7, offset: 1},"lg":{span: 8},"md":{span: 12},"sm":24}},[_c('a-form-item',{attrs:{"label":"任务描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'url2', {rules: [{ required: true, message: '请输入任务描述', whitespace: true}]} ]),expression:"[ 'url2', {rules: [{ required: true, message: '请输入任务描述', whitespace: true}]} ]"}],attrs:{"placeholder":"请输入任务描述"}})],1)],1),_c('a-col',{attrs:{"xl":{span: 9, offset: 1},"lg":{span: 10},"md":{span: 24},"sm":24}},[_c('a-form-item',{attrs:{"label":"执行人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'owner2',
            {rules: [{ required: true, message: '请选择执行人'}]}
          ]),expression:"[\n            'owner2',\n            {rules: [{ required: true, message: '请选择执行人'}]}\n          ]"}],attrs:{"placeholder":"请选择执行人"}},[_c('a-select-option',{attrs:{"value":"黄丽丽"}},[_vm._v("黄丽丽")]),_c('a-select-option',{attrs:{"value":"李大刀"}},[_vm._v("李大刀")])],1)],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":6,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"责任人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'approver2',
            {rules: [{ required: true, message: '请选择责任人'}]}
          ]),expression:"[\n            'approver2',\n            {rules: [{ required: true, message: '请选择责任人'}]}\n          ]"}],attrs:{"placeholder":"请选择责任人"}},[_c('a-select-option',{attrs:{"value":"王伟"}},[_vm._v("王伟")]),_c('a-select-option',{attrs:{"value":"李红军"}},[_vm._v("李红军")])],1)],1)],1),_c('a-col',{attrs:{"xl":{span: 7, offset: 1},"lg":{span: 8},"md":{span: 12},"sm":24}},[_c('a-form-item',{attrs:{"label":"提醒时间"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'dateRange2',
            {rules: [{ required: true, message: '请选择提醒时间'}]}
          ]),expression:"[\n            'dateRange2',\n            {rules: [{ required: true, message: '请选择提醒时间'}]}\n          ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"xl":{span: 9, offset: 1},"lg":{span: 10},"md":{span: 24},"sm":24}},[_c('a-form-item',{attrs:{"label":"任务类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'type2', {rules: [{ required: true, message: '请选择任务类型'}]} ]),expression:"[ 'type2', {rules: [{ required: true, message: '请选择任务类型'}]} ]"}],attrs:{"placeholder":"请选择任务类型"}},[_c('a-select-option',{attrs:{"value":"定时执行"}},[_vm._v("定时执行")]),_c('a-select-option',{attrs:{"value":"周期执行"}},[_vm._v("周期执行")])],1)],1)],1)],1),(_vm.showSubmit)?_c('a-form-item',[_c('a-button',{attrs:{"htmlType":"submit"}},[_vm._v("Submit")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }